import { getListSpacesQueryKey } from '@cocoonspace/sdk-js/domains/spaces/spaces'
import type { Space } from '@cocoonspace/sdk-js/types/space'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { useSpaceFavoriteUpdate } from '@cocoonspace/shared/hooks/cocoon-api'
import { useQueryClient } from '@tanstack/react-query'
import { Heart, type LucideProps } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { type ComponentProps, type MouseEventHandler, useCallback } from 'react'
import { cn } from '~/lib/utils'
import { useTracking } from '~/providers/tracking-provider'
import { useUserContext } from '~/providers/user-provider'

export const SpaceFavoriteButton = ({
	space,
	iconProps,
	...props
}: {
	space: Pick<Space, 'id' | 'title' | 'favorite'>
	iconProps?: LucideProps
} & ComponentProps<'button'>) => {
	const router = useRouter()
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const { track } = useTracking()
	const { isAuth } = useAuth()
	const { userQuery } = useUserContext()
	const openDialog = useAuthDialogStore((state) => state.openDialog)

	const { mutateAsync } = useSpaceFavoriteUpdate()

	const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
		async (e) => {
			e.stopPropagation()
			e.preventDefault()

			if (isAuth) {
				await mutateAsync({
					id: space.id,
					favorite: userQuery.data?.favorites?.includes(space.id),
				})

				userQuery.refetch()
				queryClient.invalidateQueries(getListSpacesQueryKey())
				track.updateFavorite({ space, route: router.route })
			} else {
				e.stopPropagation()

				openDialog({
					activeTab: 'register',
				})
			}
		},
		[
			isAuth,
			mutateAsync,
			space,
			userQuery,
			queryClient,
			track,
			router.route,
			openDialog,
		],
	)

	return (
		<button
			title={t('actions:addToFavs.btnLabel') as string}
			className={cn(
				`relative flex items-center justify-center rounded-full text-primary focus:outline-transparent`,
				props.className,
			)}
			onClick={handleClick}
		>
			<Heart
				size={16}
				{...iconProps}
				className={cn(iconProps?.className, {
					'fill-primary': userQuery.data?.favorites?.includes(space.id),
				})}
			/>
		</button>
	)
}
