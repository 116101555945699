import type { Space } from '@cocoonspace/sdk-js/types/space'
import { useRouter } from 'next/router'
import { StandardPrice } from './standard-price'
import { TimeBasedPrice } from './time-based-price'

export const SpaceCardPrice = ({
	space,
}: { space: Pick<Space, 'price' | 'prices'> }) => {
	const router = useRouter()

	if (space.price !== undefined && router.query.day) {
		const type =
			router.query.day && router.query.start && router.query.end
				? 'hour'
				: 'day'

		return <TimeBasedPrice type={type} space={space} />
	}

	return <StandardPrice space={space} />
}
