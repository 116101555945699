import type { Space } from '@cocoonspace/sdk-js/types/space'
import { Expand } from 'lucide-react'
import type { ComponentProps } from 'react'
import { cn } from '~/lib/utils'

export const SpaceSurface = ({
	space,
	className,
	...props
}: { space: Pick<Space, 'surface'> } & ComponentProps<'div'>) => (
	<div
		data-testid='space-surface'
		className={cn('flex flex-row items-center', className)}
		{...props}
	>
		<Expand size={14} className='mr-1' />

		{`${space.surface} m²`}
	</div>
)
