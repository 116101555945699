import type { Space } from '@cocoonspace/sdk-js/types/space'
import { Armchair } from 'lucide-react'
import type { ComponentProps } from 'react'
import { cn } from '~/lib/utils'

export const SpaceDeskCapacity = ({
	space,
	className,
	...props
}: {
	space: Pick<Space, 'capacity' | 'desk_type'>
} & ComponentProps<'div'>) => {
	const capacity =
		space.desk_type === 'cocoon'
			? Math.ceil(space.capacity / 2)
			: space.capacity

	return (
		<div
			data-testid='space-capacity'
			className={cn('flex flex-row items-center', className)}
			{...props}
		>
			<Armchair size={14} className='mr-1' />

			{`${capacity} desks disponibles`}
		</div>
	)
}
